// 软件详情
<template>
  <div class="content">
    <!-- <div class="box_left">
  
    </div> -->
    <!-- <div class="box_right"> -->
    <!-- <p v-html="list.proName"></p>
    <div v-html="list.proContent"></div>
    <div
      v-for="(item, index) in proContentTit"
      :key="index"
      class="div_content"
    > -->
      <!-- <div v-html="item" class="content"></div> -->
      <!-- </div> -->
     
       <div class="top">
          <el-breadcrumb
          separator-class="el-icon-arrow-right"
          style="margin-left: 10px; line-height: 21px"
        >
          <el-breadcrumb-item :to="{path: '/'}">首页</el-breadcrumb-item >
          <el-breadcrumb-item :to="{path: '/product'}">产品与服务</el-breadcrumb-item>
          <el-breadcrumb-item :to="{path:'/product?id=8'}">软件产品</el-breadcrumb-item>
            <el-breadcrumb-item v-if="list.proType == 1" :to="{path:'/product?id=0'}">智慧水利</el-breadcrumb-item>
            <el-breadcrumb-item v-if="list.proType == 2" :to="{path:'/product?id=1'}">智慧城管</el-breadcrumb-item>
            <el-breadcrumb-item v-if="list.proType == 3" :to="{path:'/product?id=2'}">智慧农业</el-breadcrumb-item>
          <el-breadcrumb-item>{{list.proName }}</el-breadcrumb-item>
        </el-breadcrumb>
       </div>
       <p style="font-weight: 700;
        color: #004696; text-align: center;font-size:35px" v-html="list.proName"></p>
        <div v-for="(item,index) in proContentTit" :key="index" class="item1">
            <p class="item11"v-html="item" ></p>
        </div>
       <div class="Paramter_technology">
        <img src="../assets/HardwareDetail/cs.png" alt="技术参数图片" />
        <p>推荐产品</p>
      </div>
        <div class="list">
        <div class="item" v-for="(item, index) in WisdomShui" :key="index">
          <a :href="`#/softwareDetail/${item.proId}/2`">
            <div class="devImg">
              <img :src="item.proImg" alt="硬件产品展示图" />
            </div>
            <div class="text">
              <p>{{ item.proName }}</p>
              <button>详情参数</button>
            </div>
          </a>
        </div>
      </div>
        <div class="Paramter_technology1">
        <img src="../assets/HardwareDetail/wz.png" alt="技术参数图片" />
        <p>推荐文章</p>
      </div>
      <div v-for="(item, index) in hotContent " :key="index" class="hotlist">
        <!-- <a :href="`/NewsDetail/${item.cnId}`" style="color: #000"> -->
       <a :href="`#/NewsDetail/${item.cnId}`">
          <p
            style="
              overflow: hidden;
              text-overflow: ellipsis;
              white-space: nowrap;
            "
          >
            {{ item.cnTit }}
          </p>
          <!-- </a
            > -->
        </a>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  data() {
    return {
      list: "",
      detailId: "",
      detailType: "",
      proContentTit: "",
      WisdomShui: [],
      hotContent: [],
      content: [],
      hot: [],
    };
  },
  created() {
    this.detailId = this.$route.params.id;
    this.detailType = this.$route.params.Type;
    this.getSoftwareDetail(this.detailId, this.detailType);
    this.$API.getArticleListAll().then((res) => {
      this.content = res.data;
      console.log("vvvv", res);
      res.data.map((item, index) => {
        this.hot.push(item.cnViews);
        // console.log(this.hot);
      });
      this.hot.sort((a, b) => {
        return b - a;
      });
      console.log(this.hot);
      res.data.map((item, index) => {
        if (this.hot[0] == item.cnViews) {
          // console.log(item);
          this.hotContent[0] = item;
        }
        if (this.hot[1] == item.cnViews) {
          this.hotContent[1] = item;
        }
        if (this.hot[2] == item.cnViews) {
          this.hotContent[2] = item;
        }
        if (this.hot[3] == item.cnViews) {
          this.hotContent[3] = item;
        }
        if (this.hot[4] == item.cnViews) {
          this.hotContent[4] = item;
        }
      });
      console.log(this.hotContent);
    });
  },
  methods: {
    // 根据id获取详情
    getSoftwareDetail(id, type) {
      let data = {
        proId: id,
        proType: type, // 智慧城市
      };
      console.log(data);
      this.$API.getSoftwareDetail(data).then((res) => {
        console.log("res :详情>> ", res);
        this.list = res.data;
        console.log(this.list);
        this.proContentTit = this.list.proContent.split("<p>");
        console.log(this.proContentTit);
        if (res.data.proType == 1) {
          this.getSoftware(1);
        } else if (res.data.proType == 2) {
          this.getSoftware(2);
        } else {
          this.getSoftware(3);
        }
      });
    },
    getSoftware(val) {
      let data = {
        proType: val,
      };
      this.$API.getSoftware(data).then((res) => {
        this.WisdomShui = res.data;
        function getArrayItems(arr, num) {
          var temp_array = new Array();
          for (var index in arr) {
            temp_array.push(arr[index]);
          }
          var return_array = new Array();
          for (var i = 0; i < num; i++) {
            if (temp_array.length > 0) {
              var arrIndex = Math.floor(Math.random() * temp_array.length);
              return_array[i] = temp_array[arrIndex];
              temp_array.splice(arrIndex, 1);
            } else {
              break;
            }
          }
          return return_array;
        }
        var ArrList = this.WisdomShui;
        this.WisdomShui = getArrayItems(ArrList, 4);
        console.log(this.WisdomShui, "首页软件列");
      });
    },
  },
};
</script>
<style lang="less" scoped>
.content {
  width: 60% !important;
  margin: auto !important;
  padding-bottom: 100px;
  .top {
    margin: 110px 0 30px 0;
  }
  .Paramter_technology {
    display: flex;
    margin: 50px 0;
    img {
      width: 25px;
      height: 25px;
    }
    p {
      font-size: 25px;
      margin-top: -4px;
      margin-left: 10px;
      color: #0249a1;
    }
  }
  .Paramter_technology1 {
    display: flex;
    margin: 50px 0;

    img {
      width: 30px;
      height: 30px;
    }
    p {
      font-size: 25px;
      margin-top: -2px;
      margin-left: 10px;
      color: #0249a1;
    }
  }
  .hotlist {
    a {
      color: #000;
    }
    a:hover {
      color: #004aa1;
    }
    p {
      line-height: 30px;
    }
  }
  .list {
    width: 100%;
    display: flex;
    justify-content: center;
    flex-wrap: wrap;
    .item {
      width: 23%;
      margin: 2% 1%;
      height: 270px;
      background-color: #004aa1;
      border-radius: 10px;
      box-shadow: 0 0 10px 2px rgb(210, 210, 210);
      .devImg {
        width: 100%;
        height: 150px;
        // border-radius: 8px;
        border-top-left-radius: 8px;
        border-top-right-radius: 8px;
        background-color: #fff;
        img {
          width: 100%;
          height: 150px;
          border-radius: 8px;
        }
      }
      .text {
        width: 100%;
        height: 100px;
        display: flex;
        flex-direction: column;
        justify-content: space-evenly;
        align-items: center;
        color: #fff;
        p {
          width: 80%;
          display: flex;
          justify-content: center;
          font-size: 18px;
          font-weight: 600;
          // overflow: hidden;
          text-overflow: ellipsis;
          white-space: nowrap;
        }
        button {
          cursor: pointer;
          display: block;
          padding: 5px 40px;
          border: 1px solid #fff;
          border-radius: 20px;
          color: white;
          background-color: #004aa1;
        }
        button:hover {
          color: black;
          background-color: #fff;
        }
        span:hover {
          background-color: #fff;
          color: #004aa1;
        }
      }
    }
    .gotoHardwareDetail {
      cursor: pointer;
      display: block;
      padding: 5px 40px;
      border: 1px solid #fff;
      border-radius: 20px;
      a {
        color: white;
      }
      background-color: #004aa1;
      line-height: 30px;
      margin-top: 20px;
    }
    .gotoHardwareDetail:hover {
      a {
        color: black;
      }
      // background-color: #fff;
    }
  }
  .item1 {
    .item11 {
      margin-top: 30px;
      // line-height: 25px;
      line-height: 30px;
      // text-align: center;
      /deep/p {
        // text-align: center;
        // color:#2c3e50 !important;
      }
      /deep/ h3 {
        margin-bottom: 10px;
      }
      /deep/ p {
        // text-align: center;
        margin-top: 10px;
        line-height: 25px;
        text-indent: 2em;
      }

      /deep/ img {
        margin: 10px 0;

        // display: table-cell;
        text-align: center;
        // vertical-align: middle;
        display: block;
        // disblay: block !important;
        margin: 20px auto !important;
        // max-width:865px
        width: 865px;
      }
    }
  }

  // p {
  //   img {
  //     // text-align: center;
  //     margin: auto;
  //   }
  // }

  // p:nth-child(1) {
  //   font-weight: 700;
  //   color: #004696;
  //   margin-top: 120px;
  // }
  //   p:nth-child(3) {
  //   font-weight: 700;
  //   color: #004696;
  //   margin-top: 120px;
  // }

  // .content
  // display: flex;
  // .box_right {
  //   margin-left: 30px;
  //   p:nth-child(1) {
  //     font-size: 20px;
  //     font-weight: 700;
  //     color: #0249a1;
  //   }
  //   p:nth-child(2) {
  //     margin-top: 20px;
  //   }
  //   .div_content4{
  //    /deep/ h4{
  //       margin-top: 20px;
  //     }
  //   }
  // }
}
</style>
